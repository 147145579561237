import React, { createContext, PropsWithChildren, useEffect } from 'react';
import * as authActions from 'api/requests/auth';
import {
  UserCredentials,
  UserPasswordInformation,
  UserRegistrationInformation,
  User,
} from 'types/user';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import AxiosFactory from '../helpers/AxiosFactory';
import { API_ENDPOINTS } from '../helpers/ApiEndpoints';
import { getUser } from 'api/requests/auth';
import { AxiosResponse } from 'axios';

import useCookie from 'hooks/useCookie';
import COOKIES_NAMES from 'constants/cookies';

const fetchClient = AxiosFactory.getInstance();

type AuthProps = {
  user: User | null;
  login?: (form: UserCredentials) => Promise<void>;
  logout?: () => Promise<void>;
  register?: (form: UserRegistrationInformation) => Promise<void>;
  forgotPassword?: (email: string) => Promise<AxiosResponse<any>>;
  resetPassword?: (
    token: string,
    email: string,
    form: UserPasswordInformation,
  ) => Promise<AxiosResponse<any>>;
  reloadUser?: () => void;
  resendEmailVerification?: () => void;
};
const AuthContext = createContext<AuthProps>({ user: null });

const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  let [params] = useSearchParams();
  const { data: user, error, mutate, isValidating } = useSWR<User>(
    API_ENDPOINTS.PRIVATE.GET_USER,
    getUser,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  const [, updateRegisteredUser] = useCookie<boolean>(
    COOKIES_NAMES.RegisteredUser,
  );

  const navigate = useNavigate();

  useEffect(() => {
    const handleFocus = () => {
      getUser(API_ENDPOINTS.PRIVATE.GET_USER)
        .then(userFromApi => {
          if (user?.id !== userFromApi?.id) {
            mutate();
          }
        })
        .catch(() => {
          if (Boolean(user?.id)) {
            mutate();
          }
        });
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [user, mutate]);

  const csrf = () => fetchClient.get('/csrf-cookie');

  const reloadUser = () => {
    mutate();
  };
  const register = async (form: UserRegistrationInformation) => {
    await csrf();
    await authActions.register(form);
    updateRegisteredUser(true);
    reloadUser();
  };

  const login = async (form: UserCredentials) => {
    await csrf();
    await authActions.login(form);
    updateRegisteredUser(true);
    await mutate();
    if (params.has('redirect')) {
      navigate(params.get('redirect') as string);
    }
  };

  const forgotPassword = async (email: string) => {
    await csrf();
    return authActions.forgotPassword({ email });
  };

  const resetPassword = async (
    token: string,
    email: string,
    form: UserPasswordInformation,
  ) => {
    await csrf();
    return authActions.resetPassword(token, email, form);
  };

  const resendEmailVerification = () => {
    fetchClient.post('/email/verification-notification');
  };

  const logout = async () => {
    if (!error) {
      await fetchClient.post('/logout');
      mutate(undefined);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: error || !user ? null : user,
        login,
        logout,
        register,
        forgotPassword,
        resendEmailVerification,
        resetPassword,
        reloadUser,
      }}>
      {isValidating ? null : children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { useAuth, AuthProvider };
