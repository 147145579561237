import { getCookieConsentValue } from 'react-cookie-consent';
import { setCookie } from 'helpers/cookies';
import { Cookies } from 'types/cookies';
import COOKIES_NAMES from 'constants/cookies';

export const COOKIE_NAME = COOKIES_NAMES.Consents;

export const getConsents = (): Cookies | null => {
  const cookiesRaw = getCookieConsentValue(COOKIE_NAME);

  if (!cookiesRaw) {
    return null;
  }

  const cookies: Cookies = JSON.parse(cookiesRaw);

  return cookies;
};

export const updateConsents = (newValue: Cookies) => {
  setCookie(COOKIE_NAME, newValue);
};

export const hasCookieConsents = () => {
  const cookiesRaw = getCookieConsentValue(COOKIE_NAME);

  return cookiesRaw ? true : false;
};
