import React from 'react';
import { RecoilRoot } from 'recoil';
import { SnackbarProvider } from 'notistack';

import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import FullPageSpinnerLogo from 'components/FullPageSpinnerLogo';
import { ModalProvider } from 'context/modalContext';
import { useUser } from 'context/userContext';

import theme from 'themes/scouting-light';
// @ts-ignore
import RecoilLogger from 'recoil-logger';

import { initGTM } from './helpers/gtm';
import ConsentGDPR from 'components/GDPRConsents';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const loadAuthenticatedApp = () => import('AuthenticatedApp');
const loadUnAuthenticatedApp = () => import('UnauthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(loadUnAuthenticatedApp);

function App() {
  const user = useUser();
  // pre-load the authenticated side in the background while the user's
  // filling out the login form. test
  React.useEffect(() => {
    loadAuthenticatedApp();
    initGTM();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RecoilRoot>
          <RecoilLogger />
          <SnackbarProvider
            maxSnack={3}
            hideIconVariant
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <React.Suspense fallback={<FullPageSpinnerLogo />}>
              <ModalProvider>
                {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
              </ModalProvider>
              <ConsentGDPR />
            </React.Suspense>
          </SnackbarProvider>
        </RecoilRoot>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
