//import './wdyr';
import 'video.js/dist/video-js.css';
import 'styles/index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { SWRConfig } from 'swr';

import App from 'App';
import * as serviceWorker from 'serviceWorker';
import 'i18n';
import FullPageSpinnerLogo from 'components/FullPageSpinnerLogo';
import AxiosFactory from 'helpers/AxiosFactory';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/authContext';

ReactDOM.render(
  <SWRConfig
    value={{
      fetcher: url => {
        const fetchClient = AxiosFactory.getInstance();
        return fetchClient(url).then(response => response.data);
      },
      revalidateOnFocus: false,
    }}>
    <React.Suspense fallback={<FullPageSpinnerLogo />}>
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </React.Suspense>
  </SWRConfig>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
