import TagManager from 'react-gtm-module';

import { getConsents } from 'helpers/consents';
import { removeCookie, getAllCookies } from 'helpers/cookies';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
};

const removeGoogleCookies = () => {
  const pattern = /^_[ga, gat, gid].*/;
  const allCookies = getAllCookies();
  const allCookiesName = Object.keys(allCookies);
  const googleCookies = allCookiesName.filter(name => pattern.test(name));
  googleCookies.forEach(name => removeCookie(name));
};

export const initGTM = () => {
  const consents = getConsents();

  if (!consents?.googleTagManager) {
    removeGoogleCookies();
  } else if (process.env.REACT_APP_GTM_ID) {
    TagManager.initialize(tagManagerArgs);
  }
};
