import { useAuth } from 'context/authContext';
import { User } from '../types/user';

const useUser = (): User | null => {
  const { user } = useAuth();
  return user;
};

const useIsSubscribed = () => {
  const user = useUser();
  return user?.is_subscribed || user?.on_trial;
};

export { useUser, useIsSubscribed };
