import { createTheme, Theme, Palette } from '@mui/material/styles';

const primaryMain = '#ff8600';

const background = '#fff';
const backgroundGrey = '#F5F5F5';
const spacing = 8;

const palette: Partial<Palette> = {
  mode: 'light',
  primary: {
    light: '#e4a13b',
    main: '#ea8500',
    dark: '#d66c2e',
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#b5efdd',
    main: '#09C98C',
    dark: '#05b56f',
    contrastText: '#ffffff',
  },
  background: { paper: background, default: backgroundGrey },
  common: { black: '#252525', white: '#fff' },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
};

let theme = {
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': { minHeight: 64 },
    },
  },
  spacing,
  palette,
  typography: {
    fontFamily: ['Monserrat', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      letterSpacing: '-1.5px',
    },
    h2: {
      letterSpacing: '-0.5px',
    },
    h4: {
      letterSpacing: '0.25px',
    },
    h6: {
      letterSpacing: '0.25px',
    },
    body1: {
      letterSpacing: '0.5px',
    },
    body2: {
      letterSpacing: '0.25px',
    },
    subtitle1: {
      letterSpacing: '0.15px',
    },
    subtitle2: {
      letterSpacing: '0.1px',
    },
    button: {
      letterSpacing: '1.25px',
    },
    caption: {
      letterSpacing: '0.4px',
    },
    overline: {
      letterSpacing: '2px',
    },
  },
  themeName: 'scouting-light',
};

const globalTheme: Theme = createTheme(theme);

const MuiAppBar = {
  styleOverrides: {
    root: { backgroundColor: primaryMain },
    colorPrimary: { backgroundColor: '#2c323f', color: theme.palette?.common?.white },
  },
};

const MuiDrawer = {
  styleOverrides: {
    paper: { backgroundColor: '#2c323f', color: 'white' },
  },
};

const MuiListItem = {
  styleOverrides: {
    root: {
      '&$selected': {
        backgroundColor: theme.palette?.common?.black,
        color: theme.palette?.common?.white,
        '&:hover': {
          backgroundColor: theme.palette?.common?.black,
          color: theme.palette?.common?.white,
        },
      },
    },
    button: {
      color: theme.palette?.common?.white,
    },
  },
};

const MuiInput = {
  styleOverrides: {
    underline: {
      '&::before': {
        borderBottom: '1px solid #dedede',
      },
    },
  },
};

const MuiToggleButton = {
  styleOverrides: {
    root: {
      height: 32,
      color: theme.palette?.primary?.main,
      borderRadius: '50%',
      padding: '0 3px',
      borderColor: 'transparent',
      '&:hover': {
        color: theme.palette?.primary?.main,
        backgroundColor: theme.palette?.primary?.light,
      },
      '&.Mui-selected': {
        boxShadow:
          '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
        backgroundColor: theme.palette?.primary?.main,
        color: theme.palette?.common?.white,
        border: 0,
        '&:hover': {
          backgroundColor: `${theme.palette?.primary?.dark} !important`,
        },
      },
    },
  },
};

const MuiTabs = {
  styleOverrides: {
    indicator: {
      justifyContent: 'center',
      backgroundColor: theme.palette?.primary?.main,
      height: 4,
    },
    root: {
      backgroundColor: theme.palette?.common?.white,
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    },
  },
};

const MuiTab = {
  styleOverrides: {
    root: {
      color: globalTheme.palette.text.primary,
      fontWeight: globalTheme.typography.fontWeightRegular,
      fontSize: globalTheme.typography.pxToRem(13),
      minWidth: 0,
      padding: '22px 12px',
      '&:focus': {
        opacity: 1,
      },
      '&.Mui-selected': {
        color: globalTheme.palette.text.primary,
        fontWeight: 700,
      },
    },
  },
};

const themeInstance: Theme = createTheme(
  {
    components: { MuiAppBar, MuiListItem, MuiDrawer, MuiToggleButton, MuiInput, MuiTabs, MuiTab },
  },
  globalTheme,
);

export default themeInstance;
