import AxiosFactory, { ApiResponse } from 'helpers/AxiosFactory';
import { API_ENDPOINTS } from 'helpers/ApiEndpoints';
import { JWT_KEY } from 'helpers/Constants';
import {
  UserCredentials,
  UserPasswordInformation,
  UserRegistrationInformation,
  User,
} from 'types/user';

const fetchClient = AxiosFactory.getInstance();

async function getUser(url: String) {
  try {
    const response = await fetchClient.get<ApiResponse<User>>(
      `${url}?include=premiumUser.plan,credits`,
    );
    return response.data.data;
  } catch (error) {
    logout();
    return await Promise.reject(error);
  }
}

async function getIsTrialExpired() {
  try {
    return await fetchClient(`${API_ENDPOINTS.PRIVATE.GET_IS_TRIAL_EXPIRED}`);
  } catch (error) {
    return await Promise.reject(error);
  }
}

async function login({ email, password }: UserCredentials) {
  try {
    const response = await fetchClient.post(API_ENDPOINTS.PUBLIC.LOGIN, {
      email,
      password,
    });
    const { data } = response;
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function register({
  username,
  first_name,
  last_name,
  email,
  password,
  password_confirmation,
  country_id,
  reseller_code_id,
}: UserRegistrationInformation) {
  try {
    const response = await fetchClient.post(API_ENDPOINTS.PUBLIC.REGISTER, {
      username,
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      country_id,
      reseller_code_id,
    });
    const { data } = response;
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function forgotPassword({ email }: { email: string }) {
  return await fetchClient.post(API_ENDPOINTS.PUBLIC.FORGOT_PASSWORD, {
    email,
  });
}

async function resetPassword(
  token: string,
  email: string,
  { password, password_confirmation }: UserPasswordInformation,
) {
  return await fetchClient.post(API_ENDPOINTS.PUBLIC.RESET_PASSWORD, {
    email,
    token,
    password,
    password_confirmation,
  });
}

function logout() {
  window.localStorage.removeItem(JWT_KEY);
  return Promise.resolve();
}

export {
  login,
  register,
  logout,
  forgotPassword,
  resetPassword,
  getUser,
  getIsTrialExpired,
};
