import React from 'react';
import { Trans } from 'react-i18next';

import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';
import { Id } from 'types/common';

// eslint-disable-next-line no-control-regex
export const EMAIL_REGEX: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const PASSWORD_REGEX: RegExp = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*._]{8,100}$/;
export const CREDIT_CARD_REGEX: RegExp = /[\d| ]{16,22}/;
export const USERNAME_REGEX: RegExp = /^[\w-_.]*$/;
export const CVC_REGEX: RegExp = /\d{3,4}/;
export const ZIP_CODE_REGEX: RegExp = /^(?!0{3})[0-9]{3,5}$/;
export const JWT_KEY: string = 'jwt';
export const LANGUAGES: { [key: string]: string } = {
  'us-EN': 'English',
  'es-ES': 'Español',
  'fr-FR': 'Français',
};

type rowData = {
  video_id: Id;
  name: string;
  category_id: string;
  tags: { name: string }[];
  created_at: string;
  is_public: boolean;
};
export const SCOUTINGS_COLUMNS: any[] = [
  {
    title: <Trans i18nKey="Cover">Cover</Trans>,
    data: 'video_src',
    sorting: false,
    searchable: false,
    width: '120px',
    render: (rowData: rowData) => (
      <img
        alt="skouting cover"
        src={`https://img.youtube.com/vi/${rowData.video_id}/default.jpg`}
      />
    ),
  },
  {
    title: <Trans>Name</Trans>,
    data: 'name',
    sorting: true,
    searchable: true,
    render: (rowData: { name: string }) => rowData.name,
  },
  {
    title: <Trans>Category</Trans>,
    data: 'category_id',
    sorting: true,
    searchable: true,
    render: (rowData: { category_id: string }) => rowData.category_id,
  },
  {
    title: <Trans>Tags</Trans>,
    data: 'tags',
    sorting: false,
    searchable: false,
    width: '250px',
    render: (rowData: rowData) =>
      rowData.tags.map(tag => (
        <Tooltip title={tag.name}>
          <Chip style={{ maxWidth: 120 }} size="small" label={tag.name} className="mr-2 my-1" />
        </Tooltip>
      )),
  },
  {
    title: <Trans>Created At</Trans>,
    data: 'created_at',
    sorting: true,
    width: '200px',
    searchable: false,
    defaultSort: 'desc',
    render: (rowData: rowData) => <Chip label={rowData.created_at} />,
  },
  {
    title: <Trans>Visibility</Trans>,
    data: 'is_public',
    sorting: true,
    searchable: false,
    width: '150px',
    render: (rowData: rowData) => {
      return rowData.is_public ? (
        <Chip color="primary" label={<Trans>Public</Trans>} />
      ) : (
        <Chip color="secondary" label={<Trans>Private</Trans>} />
      );
    },
  },
];

export const SCOUTINGS_TAGS_COLUMNS: any[] = [
  {
    title: <Trans>Name</Trans>,
    data: 'name',
    sorting: true,
    searchable: true,
    render: (rowData: { name: string }) => rowData.name,
  },
  {
    title: <Trans>Created At</Trans>,
    data: 'created_at',
    sorting: true,
    width: '200px',
    searchable: false,
    defaultSort: 'desc',
    render: (rowData: rowData) => <Chip label={rowData.created_at} />,
  },
];
