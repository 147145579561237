import { IdType } from 'vis-timeline';

export const ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  REGISTER_OR_LOGIN_LICENSE: '/register/invitation',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/password/reset/:token',
  USER_PROFILE: '/user/profile',
  CHANGE_PASSWORD: '/user/change-password',
  CREATE_SCOUTING: '/scoutings/create',
  UPDATE_SCOUTING_BASE: `/scoutings/:id`,
  UPDATE_SCOUTING: (id: IdType) => `/scoutings/${id}`,
  MAKE_SCOUTING_BASE: `/scoutings/:id/make`,
  MAKE_SCOUTING: (id: IdType) => `/scoutings/${id}/make`,
  SHOW_PUBLIC_SCOUTING_BASE: `/public/scoutings/:id`,
  SHOW_PUBLIC_SCOUTING: (id: IdType) => `/public/scoutings/${id}`,
  PURCHASE_PLAN_BASE: `/purchase-plan/:plan`,
  PAYMENT_SUCCESS: `/payment/status`,
  PURCHASE_PLAN: (plan: string) => `/purchase-plan/${plan}`,
  FREE_TRIAL: `/purchase-plan/free-trial`,
  LEGAL_TERMS: '/about/terms',
  PRIVACY_POLICY: '/about/privacy',
  SUBSCRIBE_PLANS: '/plans',
};
