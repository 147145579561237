import { useState, useCallback } from 'react';
import { getCookie, setCookie } from 'helpers/cookies';

function useCookie<T = string>(key: string, defaultValue?: T): [T, (value: T) => void] {
  const item = getCookie(key) ?? null;
  const value = JSON.parse(String(item)) ?? defaultValue;
  const [cookieState, setCookieState] = useState<T>(value);

  if (item === null && defaultValue !== undefined) {
    setCookie(key, defaultValue);
  }

  const updateCookie = useCallback(
    (value: T): void => {
      setCookieState(value);
      setCookie(key, value);
    },
    [key],
  );

  return [cookieState, updateCookie];
}

export default useCookie;
