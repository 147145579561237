import { Cookies } from 'react-cookie-consent';

export const setCookie = (name: string, newValue: any) => {
  Cookies.set(name, newValue);
};

export const removeCookie = (name: string) => {
  Cookies.remove(name, { path: '/' });
};

export const getCookie = (name: string) => Cookies.get(name);

export const getAllCookies = () => Cookies.get();
